<script>
import { Bar , mixins } from 'vue-chartjs'

export default {
  extends: Bar ,
  mixins: [mixins.reactiveProp],
  props: ['data', 'options', 'plugins'],
  data: function(){
    return{
      opts: {
        responsive: true,
        maintainAspectRatio: false,

        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,

        parsing: {
          xAxisKey: 'x',
          yAxisKey: 'y',
        },
        scales: {
          x: {
            // The axis for this scale is determined from the first letter of the id as `'x'`
            // It is recommended to specify `position` and / or `axis` explicitly.
            type: 'time',
            display: true,
            title: {
              display: true,
              text: 'Date'
            },
          },
          xAxes: [{
            type: 'time',
            barThickness: 'flex',  // number (pixels) or 'flex'
            categorySpacing: 0,

            ticks: {
              maxTicksLimit: 10,
            },


            time: {
              displayFormats: {
                'millisecond': 'MMM DD',
                'second': 'MMM DD',
                'minute': 'MMM DD',
                'hour': 'MMM DD',
                'day': 'MMM DD',
                'week': 'MMM DD',
                'month': 'MMM DD',
                'quarter': 'MMM DD',
                'year': 'MMM DD',
              }
            }}],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
            },
          ]
        },
        plugins: {
          title: {
            display: true,
            text: 'Custom Chart Title'
          },
          legend: {
            labels: {
              // This more specific font property overrides the global property
              font: {
                size: 55
              }
            }
          }
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    rend: function () {
      this.renderChart(this.chartData, this.opts);
    }
  }
}
</script>

<style>
</style>
